.appBar {
  position: relative;
}

.flex {
  flex: 1;
}

.cleaner {
  width: 300px;
}

.alert {
  margin-bottom: 16px;
}

.content {
  padding-top: 5rem !important;
  padding-bottom: 3rem !important;
}
