.field {
  margin-right: 1.5rem;
}

.name {
  margin-right: 1.5rem;
  width: 13rem;
}

.state {
  margin-right: 1.5rem;
  width: 5rem;
}

.postal {
  margin-right: 1.5rem;
  width: 5rem;
}

.phone {
  margin-right: 1.5rem;
  width: 9rem;
}
