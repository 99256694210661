.employee:first-of-type {
  padding-top: 0;
}

.employee-edit-form {
  .visible {
    height: 30vh;
    background-color: white;
  }
}

.schedule-card {
  .__card {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgb(240, 240, 240);
  }
}
