@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import "./styles/variables.scss";
@import "./containers/locations/styles.scss";
@import "./containers/employees/styles.scss";
@import "./containers/app/styles.scss";
@import "./containers/dashboard/styles.scss";
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
html {
  background: $light-gray;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  width: 100%;
  overflow-x: hidden;
}

button:disabled {
  opacity: 0.5;
}
button.clink {
  span {
    color: $link;
  }
}

/** TYPOGRAPHY **/
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}

/** CARDS */
.card {
  &-actions {
    float: right;
  }
  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

/** LISTS **/
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.edit-form {
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1101;
  &-appbar {
    h1 {
      color: $text !important;
    }
    svg {
      fill: $text !important;
    }
  }
}

/** UTILITY **/
.hover:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 20px !important;
}
.cp {
  cursor: pointer;
}
.df {
  display: flex !important;
}
.fww {
  flex-wrap: wrap;
}
.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.aib {
  align-items: baseline;
}
.ps {
  padding: 0.75rem;
}
.pm {
  padding: 1.5rem;
}
.pl {
  padding: 4.3rem;
}
.phl {
  padding-left: 4.3rem;
  padding-right: 4.3rem;
}
.pvm {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.pvs {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pts {
  padding-top: 0.75rem;
}
.pbs {
  padding-bottom: 0.75rem;
}
.pbxs {
  padding-bottom: 0.25rem;
}
.pls {
  padding-left: 0.75rem;
}
.prs {
  padding-right: 0.75rem;
}
.prm {
  padding-right: 1.5rem;
}
.ptm {
  padding-top: 1.5rem !important;
}
.pbm {
  padding-bottom: 1.5rem;
}
.phm {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.ma {
  margin: auto;
}
.mvm {
  margin: 1.5rem 0;
}
.mhm {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mlm {
  margin-left: 1.5rem;
}
.mrm {
  margin-right: 1.5rem !important;
}
.mls {
  margin-left: 1rem !important;
}
.mlxs {
  margin-left: 0.75rem !important;
}
.ptl {
  padding-top: 4.3rem;
}
.mvs {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mll {
  margin-left: 4.3rem;
}
.mrl {
  margin-right: 4.3rem;
}
.mrs {
  margin-right: 1rem !important;
}
.mrxs {
  margin-right: 0.5rem;
}
.mhs {
  margin-right: 1rem;
  margin-left: 1rem;
}
.mtxs {
  margin-top: 0.5rem;
}
.mvxs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mts {
  margin-top: 1rem;
}
.mtm {
  margin-top: 1.5rem;
}
.mtl {
  margin-top: 3rem;
}
.mbs {
  margin-bottom: 1rem !important;
}
.mbxs {
  margin-bottom: 0.25rem !important;
}
.mbm {
  margin-bottom: 1.5rem;
}
.dib {
  display: inline-block;
}
.bgprimary {
  background-color: $primary !important;
}
.bgwhite {
  background-color: white;
}
.fb {
  fill: $text;
}
.cw {
  color: white;
}
.fss {
  font-size: 0.95rem;
  color: $mid-gray;
}
.fsxs {
  font-size: 0.85rem;
}
.cgreen {
  color: #4caf50 !important;
}
.cred {
  color: #f44336 !important;
}
.cyellow {
  color: #ffc107 !important;
}
.cblue {
  color: $primary !important;
}
.ctrans {
  color: rgba(255, 255, 255, 0.5) !important;
}
.cwhite {
  color: white !important;
}
.cgray {
  color: $mid-gray !important;
}
.cdgray {
  color: $dark-gray !important;
}
.ct {
  color: $text !important;
}
.cred {
  color: $red !important;
}
.camber {
  color: $amber !important;
}
.cbluegrey {
  color: $blue-grey !important;
}
.bglg {
  background-color: $light-gray;
}
.bgw {
  background-color: white;
}
.b {
  font-weight: bold;
}
.separate {
  border-bottom: 1px solid $grey300;
}
.tac {
  text-align: center;
}
.tal {
  text-align: left;
}
.br {
  border-right: 1px solid $light-gray;
}
.rc {
  border-radius: 4px;
}
.ht {
  transition: opacity 3s ease-in-out, height 4s ease-in-out;
}
.hidden {
  opacity: 0;
  height: 0;
  transition: opacity 0.4s ease-in-out, height 1s ease-in-out;
  overflow: hidden;
}
.oh {
  overflow: hidden;
}
.wsn {
  white-space: nowrap;
}
.toe {
  text-overflow: ellipsis;
}
.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}
.iws {
  width: 5rem !important;
}
.iwm {
  width: 9rem !important;
}
.vh100 {
  height: 100vh;
}
.h100 {
  height: 100%;
}
.pa {
  position: absolute;
}
.pr {
  position: relative;
}
.scale {
  transform: scale(0.85);
  color: rgba(0, 0, 0, 0.3);
}
.jcfe {
  justify-content: flex-end;
}
.w300px {
  width: 300px;
  min-width: 300px;
}
.w20p {
  width: 20%;
}
.w25p {
  width: 25%;
}
.w50p {
  width: 50%;
}
.w40p {
  width: 40%;
}
.w33p {
  width: 33%;
}
.w100p {
  width: 100%;
}
.w75p {
  width: 75%;
}
.fdc {
  flex-direction: column;
}
.wspw {
  white-space: pre-wrap;
}
.flex {
  flex: 1;
}
.db {
  display: block;
}
.wmc {
  width: max-content;
}

//UTILITY SPECIFIC
.warning button {
  background: $red !important;
  div span {
    color: white !important;
  }
}
.pl38 {
  padding-left: 38px;
}
.ml40 {
  margin-left: 40px;
}
.mla {
  margin-left: auto !important;
}
.fab-right {
  position: fixed !important;
  right: 1.5rem;
  bottom: 5rem;
}
.soh {
  opacity: 0;
  transition: opacity 0.1s ease-out;
}
.hidden-actions:hover .soh {
  opacity: 1;
}
.false {
  pointer-events: none;
  opacity: 0.5;
}

.input {
  &-fake {
    position: relative;
    width: 300px;
    height: 100%;
    padding-top: 28px;
  }
  &-label-mimic {
    position: absolute;
    line-height: 22px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(0.75) translate(0px, -28px);
    transform-origin: left top 0px;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 16px;
  }
}

.message-text {
  margin-left: 38px;
}

.fixed-textarea {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 1.5rem;
  border-top: 1px solid $mid-gray;
  textarea {
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }
}
label {
  width: auto !important;
  display: block;
}

[role="menuitem"] > div > div > div {
  top: 0 !important;
}

.link {
  color: $link;
  cursor: pointer;
}

.aic.icon-container {
  align-items: baseline;
}

.row__cards {
  > div {
    margin-right: 1.5rem;
    width: 33%;
    &:last-of-type {
      margin: 0;
    }
  }
}

div[class^="MuiListItemIcon"] {
  margin-right: 0px;
}

.checkbox {
  &--small-padding {
    padding: 8px !important;
  }
}
