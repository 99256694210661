.locations {
  &-result {
    width: 100%;
  }
}

.__icon-detail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.location {
  min-height: 88px;
  &:last-of-type {
    margin-bottom: 0;
    border: none;
  }
  &-header {
    color: white;
    position: relative;
  }
  &-messages {
    display: flex;
    flex-direction: column;
    .__icon-detail {
      display: flex;
      align-items: center;
      svg {
        height: 1rem;
      }
    }
  }
  &-summary * {
    color: white;
  }
  &-edit {
    position: absolute;
    right: 5rem;
    bottom: -30px;
    button {
      svg {
        fill: $dark-gray !important;
      }
    }
  }
  &-edit-form.visible {
    padding: 4.3rem;
  }
  &-edit-form {
    .__switch {
      height: 32px;
    }
  }
}

.history {
  &-weekday {
    min-width: 3.5rem;
    border-radius: 8px;
  }
}

.location-edit-form {
  .visible {
    height: auto;
    background-color: white;
  }
}
.list {
  > div > div {
    padding: 0 !important;
  }
  .list-item {
    > div > div {
      padding: 0 !important;
    }
  }
}
.list-item .list {
  border-left: 1px solid #dedede !important;
  padding-left: 16px !important;
  margin-top: 8px;
  margin-left: 38px;
}

.cleaner {
  width: calc(25% - 1rem);
}

.inspections {
  display: flex !important;
  flex-flow: row wrap;
  flex: none !important;
  .single-inspection {
    width: calc(33% - 1.2rem);
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
    &:first-of-type {
      margin-left: 0;
    }
    &:nth-of-type(4n + 0) {
      margin-left: 0;
    }
  }
}

.messages__count {
  float: right;
}

.print__view {
  &__icon {
    position: absolute;
    right: 24px;
    top: 28px;
    cursor: pointer;
  }
}
.card-spacing {
  margin: 1.5rem 0;
}

.cleaning-history-card,
.cleaning-history,
.assigned-cleaners-card,
.edit-inspection {
  .__card {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgb(240, 240, 240);
  }
}

/** Supplies Form **/
.supplies-form {
  .__add-supply-item {
    margin-top: 16px;
  }
}
