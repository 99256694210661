.appBar {
  position: relative;
}

.flex {
  flex: 1;
}

.fab {
  position: fixed !important;
  bottom: 3rem;
  right: 1.5rem;
}

.content {
  padding-top: 5rem !important;
  padding-bottom: 3rem !important;
}

.alert {
  margin-bottom: 16px;
}
