// Variables
$primary: #3f51b5;
$text: #616161;
$light-gray: #f5f5f5;
$grey300: #e0e0e0;
$mid-gray: #9e9e9e;
$dark-gray: #424242;
$red: #f44336;
$link: #1565c0;
$blue-grey: #607d8b;
$amber: #ffc107;
