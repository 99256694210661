.privacy-policy {
  padding: 2rem;
  p {
    margin: 1rem 0;
  }
  a {
    text-decoration: underline;
    color: blue;
  }
  ul {
    padding: 2rem;
    list-style-type: disc;
  }
}
