.cleaner {
  width: 300px;
}

.input {
  margin-bottom: 16px !important;
  width: 100%;
}

.date {
  margin-bottom: 24px !important;
  width: 100%;
}

.alert {
  margin-bottom: 16px;
}
